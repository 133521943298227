<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="900">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-row>

            <v-col class="text-center" cols="6">
              <img :src="require('@/assets/account.gif')" alt="" width="100%">
            </v-col>

            <v-col cols="6">


              <v-text-field v-model="form.name"
                            :error-messages="errors.name"
                            dense
                            label="Nom *"
                            outlined>
              </v-text-field>

              <v-text-field v-model="form.phone"
                            :error-messages="errors.phone"
                            dense
                            label="Numéro de téléphone *"
                            outlined>
              </v-text-field>


              <v-text-field v-model="form.email"
                            :error-messages="errors.email"
                            dense
                            label="Email *"
                            outlined
                            type="email">
              </v-text-field>

              <v-text-field v-model="form.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :error-messages="errors.password"
                            :type="show ? 'text' : 'password'"
                            dense
                            label="Mot de passe..."
                            outlined
                            prepend-inner-icon="mdi-lock-outline"
                            @click:append="show = !show"></v-text-field>

              <v-file-input ref="image"
                            :error-messages="errors.image"
                            accept="image/*"
                            chips
                            counter
                            dense
                            label="Photo"
                            outlined
                            show-size
                            small-chips
                            @change="uploadImage"
                            @click:clear="resetImage">
              </v-file-input>

              <v-switch v-model="form.is_active" color="secondary" hide-details label="Compte active"/>

            </v-col>
          </v-row>


        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            show: false,
            title: '',
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {},
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item, title) {
            this.resetImage()
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                name: item ? item.name : '',
                phone: item ? item.phone : '',
                email: item ? item.email : '',
                is_active: item ? item.is_active : true,
                password: null,
                image: '',
                address: item ? item.address : '',
            }
            this.title = title
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/animators/' + this.form.id : '/animators',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                this.dialog = false
                this.$emit('refresh')
                this.$successMessage = 'Ce animateur a été enregistré avec succès.'
                this.saveLoading = false
            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        }
    },
}
</script>

<style scoped>

</style>