<template>
  <div :style="{width: '100%'}">
    <v-tabs background-color="transparent"
            centered
            height="65">

      <v-tab exact
             to="/regionals">
        <v-icon left>mdi-map</v-icon>
        Régionaux
      </v-tab>

      <v-tab exact
             to="/regionals/supervisors">
        <v-icon left>mdi-account-supervisor-circle-outline</v-icon>
        Superviseurs
      </v-tab>

      <v-tab exact
             to="/regionals/animators">
        <v-icon left>mdi-animation</v-icon>
        Animateurs
      </v-tab>

    </v-tabs>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>