<template>
    <div>
        <v-dialog v-model="dialog" persistent scrollable width="600">

            <v-card>

                <v-card-title>
                    Informations détaillées
                    <v-spacer/>
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-card-title>

                <v-divider/>

                <v-card-text class="pa-10">


                    <v-simple-table class="rounded-lg table-border">
                        <template v-slot:default>
                            <tbody>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium">Compte</td>
                                <td>
                                    <v-chip v-if="animator.is_active"
                                            class="font-weight-medium bg-success-subtle"
                                            small
                                            text-color="success">Actif
                                    </v-chip>

                                    <v-chip v-else
                                            class="font-weight-medium bg-red-subtle"
                                            small
                                            text-color="red">Inactif
                                    </v-chip>
                                </td>
                            </tr>

                            <tr>
                                <td class="font-weight-medium">Photo</td>
                                <td>
                                    <v-avatar size="38" v-if="animator.photo">
                                        <v-img :src="$baseUrl + animator.photo"
                                               class="zoom-pointer"
                                               @click="$refs.lightbox.open(animator.photo)"></v-img>
                                    </v-avatar>

                                    <v-avatar v-else
                                              :class="$func.randomColor()"
                                              class="font-weight-medium"
                                              size="38">
                                        {{$func.avatarName(animator.name)}}
                                    </v-avatar>
                                </td>
                            </tr>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium">Nom</td>
                                <td>
                                    {{ animator.name }}
                                </td>
                            </tr>

                            <tr>
                                <td class="font-weight-medium">Email</td>
                                <td>
                                    <a :href="'mailto:'+animator.email"
                                       class="orange--text">
                                        {{ animator.email }}
                                    </a>
                                </td>
                            </tr>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium">N°Téléphone</td>
                                <td>
                                    {{ animator.phone }}
                                </td>
                            </tr>
                            
                            <tr>
                                <td class="font-weight-medium">Date de création</td>
                                <td>
                                    {{ animator.created_at }}
                                </td>
                            </tr>

                            </tbody>
                        </template>
                    </v-simple-table>

                </v-card-text>
            </v-card>
        </v-dialog>

        <Lightbox ref="lightbox"/>

    </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            animator: {}
        }
    },
    methods: {
        open(animator) {
            this.animator = animator
            this.dialog = true
        },
    },
}
</script>

<style scoped>

</style>